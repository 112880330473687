import React, { Component, createContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const CurrentTrackContext = createContext()

export function CurrentTrackProvider(props) {
  const data = useStaticQuery(
    graphql`
      {
        allFeedPodcastRss {
          edges {
            node {
              id
              title
              description
              enclosure {
                url
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const [isCurrentPlaying, _setIsCurrentPlaying] = useState(false)
  const [currentPlaying, _setCurrentPlaying] = useState()
  const [currentPlayingAudio, _setCurrentPlayingAudio] = useState()

  const [trackToProgress, setTrackToProgress] = useState({})

  const setCurrentPlaying = (idx, startTime) => {
    // If we're changing indices, we have to do some extra work
    if (idx !== currentPlaying) {
      const episode = data.allFeedPodcastRss.edges[idx].node
      if (currentPlayingAudio) currentPlayingAudio.pause()
      const newAudio = new Audio(episode.enclosure.url)
      if (startTime) {
        newAudio.currentTime = startTime
      }
      newAudio.play()
      _setCurrentPlayingAudio(newAudio)
      _setCurrentPlaying(idx)
      _setIsCurrentPlaying(true)
    } else {
      // Otherwise, restart the current audio
      currentPlayingAudio.play()
      _setIsCurrentPlaying(true)
    }
  }

  const setTrackProgressOnly = (episodeIdx, trackProgress) => {
    setTrackToProgress({ ...trackToProgress, [episodeIdx]: trackProgress })
  }

  const setTrackProgress = (episodeIdx, trackProgress) => {
    if (episodeIdx === currentPlaying && currentPlayingAudio) {
      currentPlayingAudio.currentTime = trackProgress
    }
    setTrackProgressOnly(episodeIdx, trackProgress)
  }

  const pauseCurrentPlaying = () => {
    if (currentPlayingAudio) currentPlayingAudio.pause()
    _setIsCurrentPlaying(false)
  }

  return (
    <CurrentTrackContext.Provider
      value={{
        currentPlaying,
        isCurrentPlaying,
        currentPlayingAudio,
        setCurrentPlaying,
        pauseCurrentPlaying,
        setTrackProgress,
        trackToProgress,
        setTrackProgressOnly,
      }}
      {...props}
    />
  )
}

export class CurrentTrackConsumer extends Component {
  render() {
    return (
      <CurrentTrackContext.Consumer>
        {this.props.children}
      </CurrentTrackContext.Consumer>
    )
  }
}
