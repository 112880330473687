import React from "react"

// custom typefaces
import "@fontsource/montserrat/variable.css"
import "@fontsource/merriweather"

// tailwind css
import "./src/styles/global.css"

// audio track context
import { CurrentTrackProvider } from "./src/components/context"

export const wrapRootElement = ({ element }) => (
  <CurrentTrackProvider>{element}</CurrentTrackProvider>
)
